import { useDispatch, useSelector } from "react-redux";

import publicConfig from "config/public";
import { FulfillmentPreference } from "custom-types/User";
import { USER_FULFILLMENT_PREFERENCE_CHANGE } from "redux/reducers/user";
import { getUserFulfillmentPreference } from "redux/selectors/user";
import { setCookie } from "utils/cookies";

import useDomainCountryCode from "./useDomainCountryCode";

const useUserFulfillmentPreference = () => {
  const countryCode = useDomainCountryCode();
  const { cookieDomainCa, cookieDomain } = publicConfig;
  const dispatch = useDispatch();

  const setUserFulfillmentPreference = (preference: string) => {
    setCookie(
      "userFulfillmentPreference",
      // @ts-ignore (fix me please, do not replicate)
      FulfillmentPreference[preference.toUpperCase()] ||
        FulfillmentPreference.DELIVERY,
      countryCode === "CA" ? cookieDomainCa : cookieDomain,
    );

    dispatch({
      fulfillmentPreference: preference,
      type: USER_FULFILLMENT_PREFERENCE_CHANGE,
    });
  };

  return {
    setUserFulfillmentPreference,
    userFulfillmentPreference: useSelector(getUserFulfillmentPreference),
  };
};

export default useUserFulfillmentPreference;
