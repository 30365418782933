import React from "react";

import {
  DeliveryServiceArea,
  Dispensary,
  DispensaryServiceAreaRanges,
} from "custom-types/Dispensary";
import { getPaymentMethodsString } from "utils/fulfillmentUtils";

import ScheduleStatus from "components/botanic/ScheduleStatus";
import Scooter from "components/Icons/scooter.svg";

type Props = {
  dispensary: Dispensary;
  deliveryZone?: DeliveryServiceArea;
  fulfillmentType: "pickup" | "delivery" | "store";
  pickupReadinessLabel?: string;
};

type FeeProps = {
  deliveryServiceAreaRanges: DispensaryServiceAreaRanges;
  deliveryZone?: DeliveryServiceArea;
};

const FreeDelivery = ({
  deliveryServiceAreaRanges,
  deliveryZone,
}: FeeProps) => {
  const { minFreeDeliveryMinimum, maxFreeDeliveryMinimum } =
    deliveryServiceAreaRanges;
  const freeDeliveryMin = React.useMemo(() => {
    if (deliveryZone?.freeDeliveryMinimum) {
      // IF retailer has free delivery min && delivery address is known, display the associated free delivery min
      return `$${deliveryZone.freeDeliveryMinimum}`;
    } else if (minFreeDeliveryMinimum === maxFreeDeliveryMinimum) {
      return `$${minFreeDeliveryMinimum}`;
    } else {
      // IF retailer has free delivery min AND customer delivery address is NOT known, display the range (min to max)
      // IF retailer has free delivery min AND customer delivery address is outside of the delivery zones, display
      //   the range (min to max)
      return `$${minFreeDeliveryMinimum} - $${maxFreeDeliveryMinimum}`;
    }
  }, [minFreeDeliveryMinimum, maxFreeDeliveryMinimum]);

  // IF retailer has no delivery services areas that utilize free delivery minimum, do not show Free delivery min
  //   spend line item
  if (
    !deliveryZone?.freeDeliveryMinimum &&
    !minFreeDeliveryMinimum &&
    !maxFreeDeliveryMinimum
  ) {
    return null;
  }
  return (
    <>
      <span>Free delivery min spend</span>
      <span data-testid="free-delivery-order-minimum">{freeDeliveryMin}</span>
    </>
  );
};

const OrderMinimum = ({
  deliveryServiceAreaRanges,
  deliveryZone,
}: FeeProps) => {
  const { minOrderMinimum, maxOrderMinimum } = deliveryServiceAreaRanges;
  const orderMin = React.useMemo(() => {
    if (deliveryZone?.orderMinimum) {
      // IF delivery address is known, display the associated order minimum
      return `$${deliveryZone?.orderMinimum}`;
    } else if (minOrderMinimum === maxOrderMinimum) {
      // IF delivery min/max are the same, don't show range.
      return `$${minOrderMinimum}`;
    } else {
      // IF delivery address is NOT known, display the range (min to max)
      // IF delivery address is outside of the delivery zones, display the range (min to max)
      return `$${minOrderMinimum} - $${maxOrderMinimum}`;
    }
  }, [minOrderMinimum, maxOrderMinimum]);

  return (
    <>
      <span>Order minimum</span>
      <span data-testid="delivery-order-minimum">{orderMin}</span>
    </>
  );
};

const DeliveryFee = ({ deliveryServiceAreaRanges, deliveryZone }: FeeProps) => {
  const { minFee, maxFee } = deliveryServiceAreaRanges;
  const deliveryFee = React.useMemo(() => {
    if (deliveryZone?.fee) {
      // IF delivery address is known, display the associated delivery fee
      return `$${deliveryZone?.fee}`;
    } else if (minFee === maxFee) {
      // IF fee min/max are the same, don't show range.
      return `$${minFee}`;
    } else {
      // IF delivery address is NOT known, display the range (min to max)
      // IF delivery address is outside of the delivery zones, display the range (min to max)
      return `$${minFee} - $${maxFee}`;
    }
  }, [minFee, maxFee]);

  return (
    <>
      <span>Delivery fee</span>
      <span data-testid="delivery-fee">{deliveryFee}</span>
    </>
  );
};

const RetailerDetailsCard: React.FC<Props> = ({
  dispensary,
  deliveryZone,
  fulfillmentType,
  pickupReadinessLabel,
}) => {
  const {
    deliveryPaymentMethods,
    deliveryServiceAreaRanges,
    pickupMinutesMaximum,
    pickupMinutesMinimum,
    pickupPaymentMethods,
    pickupTimeEstimate,
  } = dispensary;

  // Because deliveryServiceAreaRanges can be null, there is no way to use a default value
  // for the destructured object. Therefore, we have to set it to an empty object and then cast it
  // to the correct type. Without this, we will get a type error when trying to access the properties
  // of the object.
  const deliveryRanges =
    deliveryServiceAreaRanges || ({} as DispensaryServiceAreaRanges);

  const {
    minDeliveryDurationMinimum,
    minFreeDeliveryMinimum,
    minOrderMinimum,
    maxDeliveryDurationMaximum,
  } = deliveryRanges;

  const isDelivery = fulfillmentType?.toLowerCase() === "delivery";
  const { orderMinimum, fee, freeDeliveryMinimum } = deliveryZone || {};

  const paymentMethods = getPaymentMethodsString(
    isDelivery ? deliveryPaymentMethods : pickupPaymentMethods,
  );

  // @ts-ignore (fix me please, do not replicate)
  const formattedTimeValue = (minutes) => {
    if (!Number.isInteger(minutes)) {
      return "Same day";
    }

    if (minutes === 2880) {
      return "Next day";
    }
    if (minutes === 1440) {
      return "Same day";
    }
    if (minutes > 60) {
      return `${Math.floor(minutes / 60)} hr ${minutes % 60} min`;
    }
    return `${minutes} min`;
  };

  // Since the min or max can be number, string, null or undefined we have
  // to check several things. First we check if its null OR undefined using
  // !=. Then we check if its an empty string. These first two checks prevent
  // the Number function from turning undefined, null or "" into a 0. Then
  // in the if statement we make sure the values are not false because
  // simply checking if they exist will return undefined if the values are 0.
  // Then we verify that the Number conversion did not create NaN with isNaN.
  const formattedTimeEstimate = (
    min?: number | string | null,
    max?: number | string | null,
  ) => {
    const minimum = min != null && min !== "" && Number(min);
    const maximum = max != null && max !== "" && Number(max);

    if (
      minimum === false ||
      isNaN(minimum) ||
      maximum === false ||
      isNaN(maximum)
    ) {
      return undefined;
    }
    if (
      (minimum === 0 && maximum === 1440) ||
      (minimum === 0 && maximum === 0) ||
      minimum >= maximum
    ) {
      return "Same day";
    }
    if (minimum === 0 && maximum === 2880) {
      return "Next day";
    }
    if (minimum === 0 && maximum < 1440) {
      return `Up to ${formattedTimeValue(maximum)}`;
    }
    if (minimum === maximum) {
      return formattedTimeValue(minimum);
    }

    return `${formattedTimeValue(minimum)} - ${formattedTimeValue(maximum)}`;
  };

  const deliveryEstimate =
    formattedTimeEstimate(
      deliveryZone?.deliveryDurationMinimum,
      deliveryZone?.deliveryDurationMaximum,
    ) ||
    formattedTimeEstimate(
      minDeliveryDurationMinimum,
      maxDeliveryDurationMaximum,
    );

  const pickupEstimate = pickupReadinessLabel
    ? pickupReadinessLabel
    : formattedTimeEstimate(pickupMinutesMinimum, pickupMinutesMaximum) ||
      formattedTimeValue(pickupTimeEstimate);

  const timeEstimate = isDelivery ? deliveryEstimate : pickupEstimate;

  return (
    <div>
      <h2 className="text-xs uppercase">{fulfillmentType} Info</h2>
      {fulfillmentType === "delivery" &&
        dispensary.scheduledDeliveryEnabled && (
          <div className="pt-sm pb-lg">
            <div className="border border-light-grey">
              <span className="flex p-2.5">
                <Scooter height="24" width="24" />
                <div className="pl-sm text-xs">
                  Scheduling available in checkout
                </div>
              </span>
            </div>
          </div>
        )}
      <div className="text-xs grid grid-cols-2 grid-template-rows-[repeat(5, 0.5fr)] gap-y-1 mt-xs">
        <span className="font-bold">Today’s hours</span>
        <ScheduleStatus
          currentStatus={dispensary.currentStatuses?.[fulfillmentType]}
          scheduleType={fulfillmentType}
          timeZone={dispensary.timeZone}
        />
        {fulfillmentType === "delivery" ? (
          !dispensary.scheduledDeliveryEnabled && (
            <>
              <span data-testid="deliveryEstimate">
                {isDelivery ? "Delivery estimate" : "Time"}
              </span>
              <span>
                {!timeEstimate && "Ready in about"}
                {timeEstimate || " same day"}
              </span>
            </>
          )
        ) : (
          <>
            <span>{isDelivery ? "Delivery estimate" : "Time"}</span>
            <span>
              {!timeEstimate && "Ready in about"}
              {timeEstimate || " same day"}
            </span>
          </>
        )}
        {isDelivery && (orderMinimum || minOrderMinimum) && (
          <OrderMinimum
            deliveryZone={deliveryZone}
            deliveryServiceAreaRanges={deliveryRanges}
          />
        )}

        {isDelivery && fee !== undefined && (
          // fee can be 0 so an equality check against undefined is required
          <DeliveryFee
            deliveryZone={deliveryZone}
            deliveryServiceAreaRanges={deliveryRanges}
          />
        )}
        {isDelivery && (freeDeliveryMinimum || minFreeDeliveryMinimum) && (
          <FreeDelivery
            deliveryZone={deliveryZone}
            deliveryServiceAreaRanges={deliveryRanges}
          />
        )}
        <span>Payment</span>
        <span>{paymentMethods}</span>
      </div>
    </div>
  );
};

export default RetailerDetailsCard;
