import { Location } from "custom-types/Location";

export const normalizeGoogleGeocodeResponse = ({
  response,
  isUserLocation = true,
}: {
  response: google.maps.GeocoderResult[];
  isUserLocation?: boolean;
}): Location => {
  let sublocality: string | undefined;
  let city: string | undefined;
  let state: string | undefined;
  let stateCode: string | undefined;
  let zip: string | undefined;
  let country: string | undefined;
  let countryCode: string | undefined;

  const result = response[0];
  result.address_components.forEach((component) => {
    if (component.types.indexOf("postal_code") !== -1) {
      zip = component.short_name;
    } else if (component.types.indexOf("sublocality") !== -1) {
      sublocality = component.long_name;
    } else if (component.types.indexOf("locality") !== -1) {
      city = component.short_name;
    } else if (component.types.indexOf("postal_town") !== -1 && !city) {
      city = component.short_name;
    } else if (component.types.indexOf("administrative_area_level_1") !== -1) {
      stateCode = component.short_name;
      state = component.long_name;
    } else if (component.types.indexOf("country") !== -1) {
      countryCode = component.short_name;
      country = component.long_name;
    }
  });

  // Some places are coming back from google with a sublocality but not a locality
  // If this happens, move the sublocality into the city position.
  // e.g.:  928 Brooklyn Avenue, Brooklyn, NY
  if (!city && sublocality) {
    city = sublocality;
    sublocality = "undefined";
  }

  const locationSlug =
    [sublocality, city, stateCode, countryCode].filter(Boolean).join("-") ||
    country;

  const location = {
    city: city,
    coordinates: {
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    },
    country: country,
    country_code: countryCode,
    formatted_location: getFormattedLocation({
      city,
      country,
      countryCode,
      state,
      stateCode,
      sublocality,
    }),
    isUserLocation: isUserLocation,
    slug: locationSlug?.toLowerCase(),
    state: state,
    state_code: stateCode,
    sublocality: sublocality,
    zip: zip,
  };

  return location;
};

const getFormattedLocation = (location: {
  sublocality?: string;
  city?: string;
  stateCode?: string;
  state?: string;
  countryCode?: string;
  country?: string;
}) => {
  const { sublocality, city, stateCode, state, countryCode, country } =
    location;

  if ((sublocality || city) && stateCode) {
    return [sublocality, city, stateCode].filter(Boolean).join(", ");
  } else if (state && countryCode) {
    return [state, countryCode].filter(Boolean).join(", ");
  } else {
    return country;
  }
};
