import React, { useCallback, useEffect, useState } from "react";

import getDeliveryZoneFromAddress from "api/requests/compliance/getDeliveryZoneFromAddress";
import {
  DeliveryZoneDecisionResponse,
  DispensaryViolation,
} from "custom-types/Compliance";
import { DeliveryServiceArea, Dispensary } from "custom-types/Dispensary";
import { Location } from "custom-types/Location";

import Button from "components/botanic/Button";
import { Modal, ModalProps } from "components/botanic/Modal";
import RetailerDetailsCard from "components/RetailerDetailsCard";

import DeliveryValidationAutocomplete from "./DeliveryValidationAutocomplete";
import { isValidAddress, useStatus } from "./utils";

/**
 * DeliveryValidationModal provides a modal for the user to confirm their delivery address is within the delivery range
 * of a dispensary (if a specific dispensary is provided - such as in /dispensary-info), or can be used to validate all
 * dispensaries in the delivery area (as used in /dispensaries).
 */

type DeliveryValidationModalProps = ModalProps & {
  showModal: boolean;
  complianceCallback?: (result: DeliveryZoneDecisionResponse) => void;
  defaultAddress: Location;
  /**
   * Optional dispensary object provides dispensary-specific details for compliance as well as to
   * populate the pickup and/or delivery ordering details in RetailerDetailsCard. Omitting this will hide
   * the RetailerDetailsCards.
   */
  dispensary?: Dispensary;
  deliveryZone?: DeliveryServiceArea;
  getViolations?: (
    userAddress: Location & { latitude: number; longitude: number },
  ) => DispensaryViolation[];
  handlePrimaryAction: (address: Location) => void;
  handleSecondaryAction?: (address: Location) => void;
  primaryActionLabel: string;
  secondaryActionLabel?: string;
  successMessage?: string;
  isDeliveryMode?: boolean;
};

export const DeliveryValidationModal: React.FC<
  DeliveryValidationModalProps
> = ({
  showModal,
  complianceCallback,
  defaultAddress,
  dispensary,
  deliveryZone,
  getViolations,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionLabel,
  secondaryActionLabel,
  successMessage = "",
  isDeliveryMode = true,
  ...props
}) => {
  const {
    id: dispensaryId,
    deliveryEnabled,
    hasDeliveryEnabled,
  } = dispensary || {};

  const [userAddress, setUserAddress] = useState<Location>(defaultAddress);
  const [deliveryZoneResult, setDeliveryZoneResult] =
    useState<DeliveryZoneDecisionResponse>();
  const { internalStatus, resetStatus, setInternalStatus } = useStatus();

  // @ts-ignore (fix me please, do not replicate)
  const formatAddress = (address) => {
    if (!address) {
      return undefined;
    }

    if (address.latitude && address.longitude) {
      return address;
    }
    return {
      ...address,
      latitude: address.coordinates?.latitude || address.coordinates?.lat || "",
      longitude:
        address.coordinates?.longitude || address.coordinates?.lon || "",
    };
  };

  useEffect(() => {
    // handle if default address is changed (e.g. user changes
    // address in the header location picker)
    if (defaultAddress !== userAddress) {
      setUserAddress(defaultAddress);
    }

    // Make initial zone-specific compliance call on mount
    const fetchZoneInfo = async () => {
      const formattedAddress = formatAddress(defaultAddress);

      if (
        dispensaryId &&
        isValidAddress(formattedAddress) &&
        complianceCallback
      ) {
        try {
          const result = await getDeliveryZoneFromAddress(
            dispensaryId,
            formattedAddress,
          );
          complianceCallback(result);
        } catch {
          complianceCallback({ result: false, violations: [] });
        }
      }
    };

    fetchZoneInfo();
  }, [defaultAddress]);

  const addressValidation = useCallback(
    // @ts-ignore (fix me please, do not replicate)
    async (userAddress) => {
      setDeliveryZoneResult(undefined);

      if (!userAddress || !userAddress.formattedAddress || !userAddress.city) {
        resetStatus();
        return;
      }

      let violations: DispensaryViolation[] = [];
      if (dispensaryId && isValidAddress(userAddress) && !getViolations) {
        const result = await getDeliveryZoneFromAddress(
          dispensaryId,
          userAddress,
        );
        setDeliveryZoneResult(result);
        violations = result?.violations || [];
      } else if (getViolations) {
        const result = await getViolations(userAddress);
        violations = result || [];
      }

      if (violations?.length) {
        setInternalStatus({ error: true, msg: violations[0]?.message });
      } else {
        setInternalStatus({ error: false, msg: successMessage });
      }
    },
    [dispensaryId, getViolations],
  );

  // @ts-ignore (fix me please, do not replicate)
  const handleSetUserAddress = async (address) => {
    // if address is valid, store in state and fetch compliance violations

    // set latitude and longitude properties from coordinates object
    const addressWithLatLng = formatAddress(address);
    if (isValidAddress(addressWithLatLng)) {
      setUserAddress(addressWithLatLng);
      addressValidation(addressWithLatLng);
    }
  };

  // Letting users save their address regardless of violations for now, may refine this later
  // userAddress will still be a verified Google address and pass isValidAddress()
  const handlePrimaryClick = () => {
    handlePrimaryAction(userAddress);
    if (complianceCallback && deliveryZoneResult) {
      complianceCallback(deliveryZoneResult);
    }
  };

  const showDelivery = hasDeliveryEnabled || deliveryEnabled;

  if (!showModal) {
    return null;
  }

  return (
    <Modal title={isDeliveryMode ? "Deliver To" : "Pickup near"} {...props}>
      <div className="flex flex-col justify-between h-[calc(100%_-_65px)]">
        <div className="flex-1 mb-lg">
          <DeliveryValidationAutocomplete
            handleStatusChange={setInternalStatus}
            setUserAddress={handleSetUserAddress}
            status={internalStatus}
            userAddress={userAddress}
          />
          {dispensary && showDelivery && (
            <RetailerDetailsCard
              dispensary={dispensary}
              deliveryZone={
                internalStatus.error
                  ? undefined
                  : deliveryZoneResult?.deliveryServiceArea || deliveryZone
              }
              fulfillmentType="delivery"
            />
          )}
        </div>

        <Button
          className="m-auto"
          onClick={handlePrimaryClick}
          title={primaryActionLabel}
          data-testid="delivery-validation-modal-primary-button"
        >
          {primaryActionLabel}
        </Button>
        {!!secondaryActionLabel && (
          <Button
            className="m-auto mt-lg text-sm text-default"
            onClick={() => handleSecondaryAction?.(userAddress)}
            title={secondaryActionLabel}
            variant="text"
          >
            {secondaryActionLabel}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default DeliveryValidationModal;
