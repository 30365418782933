import React from "react";
import cx from "classnames";
import Autocomplete from "react-google-autocomplete";

import publicConfig from "config/public";
import { Location } from "custom-types/Location";

import CloseIcon from "components/Icons/x.svg";
import Status from "components/Status";

import { isValidAddress, useStatus } from "./utils";

const { googleMapsApiKey } = publicConfig;

type AddressType = {
  formattedAddress?: string;
  addressLine2?: string;
};

type StatusType = { error: boolean; msg: string };

const getPlacesNameValue = (
  placesResponse: google.maps.places.PlaceResult,
  type: string,
  useLongNameProperty = false,
) => {
  const addressComponent = placesResponse?.address_components?.find((el) =>
    el.types.includes(type),
  );

  const name =
    useLongNameProperty === true
      ? addressComponent?.long_name
      : addressComponent?.short_name;

  return name || "";
};

export type AutocompleteInputProps = {
  address: AddressType;
  className?: string;
  formattedAddress: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (address: AddressType) => void;
  onStatusChange: ({ error, msg }: { error: boolean; msg: string }) => void;
  onClear: () => void;
  placeholder?: string;
  status: StatusType;
  name?: string;
};

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  className,
  formattedAddress,
  onChange,
  onSelect,
  onStatusChange,
  onClear,
  status = { error: false, msg: "" },
  ...props
}: AutocompleteInputProps) => {
  const { resetStatus, internalStatus, setInternalStatus } = useStatus();

  const setInternalErrorMessage = (msg: string) => {
    onStatusChange({ error: !!msg, msg });

    setInternalStatus({ error: true, msg });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      resetStatus();
    }
    onChange(e);
  };

  const onPlaceSelected = (place?: google.maps.places.PlaceResult) => {
    resetStatus();

    if (!place?.geometry) {
      setInternalErrorMessage(
        "Please provide your address for delivery (should be selectable from the dropdown).",
      );
      return undefined;
    }

    const { lat, lng } = place.geometry.location || {};
    const postalCode = getPlacesNameValue(place, "postal_code");

    const res: Location = {
      addressLine2: "",
      city:
        getPlacesNameValue(place, "locality") ||
        getPlacesNameValue(place, "postal_town"),
      country: getPlacesNameValue(place, "country", true),
      countryCode: getPlacesNameValue(place, "country"),
      formattedAddress: place.formatted_address,
      latitude: lat && lat(),
      longitude: lng && lng(),
      placeId: place.place_id,
      postalCode,
      state: getPlacesNameValue(place, "administrative_area_level_1", true),
      stateCode: getPlacesNameValue(place, "administrative_area_level_1"),
      street: {
        name: getPlacesNameValue(place, "route"),
        number: getPlacesNameValue(place, "street_number"),
      },
      sublocality: getPlacesNameValue(place, "neighborhood", true),
      zip: postalCode,
    };

    // Handle if an invalid and/or incomplete address is entered
    if (!isValidAddress(res)) {
      setInternalErrorMessage("Please select a specific address.");
    }

    if (!postalCode) {
      setInternalErrorMessage(
        "Sorry, this address is invalid (you might be missing a zip code).",
      );
    }

    onSelect(res);
  };

  return (
    <div className="autocomplete-container">
      <div className="relative flex items-center">
        <Autocomplete
          apiKey={googleMapsApiKey}
          aria-label="Address"
          className={cx(
            "google-autocomplete-input bg-leafly-white border border-light-grey font-bold fs-block pl-lg rounded text-sm pr-xxl",
            { "border-error": status.error, className },
          )}
          data-testid="address-text-input"
          name="address-line-1"
          onChange={handleOnChange}
          onPlaceSelected={onPlaceSelected}
          placeholder="Check address availability"
          type="text"
          options={{
            types: ["address"],
          }}
          value={formattedAddress}
          {...props}
        />
        <button
          aria-label="clear-input"
          onClick={onClear}
          className="absolute right-0 p-md"
        >
          <CloseIcon height="16" width="16" />
        </button>
      </div>

      {!(internalStatus.msg || internalStatus.error) && (
        <Status
          className="text-secondary"
          hasError={false}
          msg="We won’t share this without your permission"
          style={{ fontWeight: "normal" }}
        />
      )}

      {internalStatus.msg && (
        <Status hasError={internalStatus.error} msg={internalStatus.msg} />
      )}
      {!internalStatus.error && (
        <Status hasError={status.error} msg={status.msg} />
      )}

      {/* Hide some Google branding elements and modifies the design of autocomplete from "react-google-autocomplete" */}
      <style jsx global>{`
        .pac-container.pac-logo::after {
          display: none;
        }

        .pac-container {
          color: var(--color-primary);
        }

        .pac-icon {
          display: none;
        }

        .pac-item,
        .pac-item-query {
          text-align: center;
          color: var(--color-primary);
          font-size: var(--font-size-xs);
          line-height: 24px;
        }

        .pac-item {
          padding: 8px;
        }

        .pac-matched {
          font-weight: 400;
        }
      `}</style>
    </div>
  );
};

export default AutocompleteInput;
