import kebabCase from "lodash/kebabCase";

export const getLocationSlug = ({
  city,
  state_code,
  state,
}: {
  city?: string;
  state_code?: string;
  state?: string;
}): string => {
  if (!city) {
    return kebabCase([state].join(" "));
  }

  return kebabCase([city, state_code || state].join(" "));
};
