import logError from "utils/logError";

import initializeGoogleMaps from "./initializeGoogleMaps";

export default async () => {
  const isFunctionInitialized = () =>
    typeof google !== "undefined" && google?.maps?.Geocoder;

  try {
    if (!isFunctionInitialized()) {
      await initializeGoogleMaps();
    }

    if (isFunctionInitialized()) {
      return new google.maps.Geocoder();
    }
  } catch {
    logError("Could not intitialize google maps geocoder");
  }
};
