import React from "react";
import isEmpty from "lodash/isEmpty";

import { Location } from "custom-types/Location";

export const isValidAddress = (address: Location) =>
  address?.latitude &&
  address?.longitude &&
  !isEmpty(address?.formattedAddress) &&
  !isEmpty(address?.street?.name) &&
  !isEmpty(address?.street?.number);

export const useStatus = (initialStatus = { error: false, msg: "" }) => {
  const [internalStatus, setInternalStatus] = React.useState(initialStatus);
  const resetStatus = () => setInternalStatus(initialStatus);
  return { internalStatus, resetStatus, setInternalStatus };
};
