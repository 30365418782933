import React from "react";
import cx from "classnames";

type StatusProps = {
  hasError?: boolean;
  msg: string;
  className?: string;
  style?: React.CSSProperties;
};

const Status: React.FC<StatusProps> = ({
  hasError = false,
  msg,
  className = "",
  style = {},
  ...props
}) => {
  return (
    <div
      data-testid="status-msg"
      role="alert"
      style={{ maxWidth: "300px", minHeight: "12px", ...style }}
      className={cx(
        `text-xs status-msg font-bold m-xs text-${
          hasError ? "error" : "success"
        }`,
        className,
      )}
      {...props}
    >
      {msg}
    </div>
  );
};

export default Status;
