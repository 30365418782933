import React from "react";
import classNames from "classnames";

import { CurrentStatus } from "custom-types/Dispensary";
import { getStatusMessage } from "utils/dispensaryScheduleUtils";

import SolidArrowIcon from "components/Icons/arrows/SolidArrowIcon";

type Props = {
  className?: string;
  currentStatus?: CurrentStatus | null;
  preorderConfigured?: boolean;
  scheduleType?: "pickup" | "delivery" | "store";
  showArrow?: boolean;
  timeZone?: string;
};

const ScheduleStatus: React.FC<Props> = ({
  className,
  currentStatus,
  preorderConfigured,
  scheduleType = "store",
  showArrow = false,
  timeZone,
}) => {
  const preorderActive =
    scheduleType !== "delivery" && preorderConfigured && !currentStatus?.isOpen;

  const currentStatusMessage = currentStatus
    ? getStatusMessage(
        !!preorderConfigured,
        currentStatus,
        timeZone as string,
        scheduleType,
      )
    : null;

  const formattedCurrentStatusMessage = currentStatusMessage
    ? [
        currentStatusMessage[0].toUpperCase(),
        currentStatusMessage.slice(1),
      ].join("")
    : null;

  if (!formattedCurrentStatusMessage) {
    return null;
  }

  const statusClasses = classNames(
    "rounded-full h-[8px] w-[8px] items-center mr-xs",
    {
      "border-2": preorderActive,
      "border-4": !preorderActive,
      "border-error": (!currentStatus?.isOpen && !preorderActive) || null,
      "border-green": currentStatus?.isOpen || preorderActive,
    },
  );

  if (!formattedCurrentStatusMessage) {
    return null;
  }

  return (
    <div
      className={classNames(
        className,
        "font-bold flex flex-row items-baseline text-xs",
      )}
    >
      <div className={statusClasses} />
      <div className="flex">
        <span data-testid="formatted-message">
          {formattedCurrentStatusMessage}
        </span>
        {showArrow && <SolidArrowIcon height="20" width="20" />}
      </div>
    </div>
  );
};

export default ScheduleStatus;
