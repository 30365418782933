import { Loader } from "@googlemaps/js-api-loader";

import publicConfig from "config/public";
import logError from "utils/logError";

const { googleMapsApiKey } = publicConfig;

export default async () => {
  // Because google maps is picky, any libraries that need to be loaded at
  // any point need to be loaded no matter what when google maps is first
  // loaded in.
  const loader = new Loader({
    apiKey: googleMapsApiKey,
    libraries: ["geometry", "places"],
  });

  try {
    await loader.load();
  } catch {
    logError("Could not intitialize google maps");
  }
};
