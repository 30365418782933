import { Location } from "custom-types/Location";
import initializeGoogleMapsGeocoder from "utils/maps/initializeGoogleMapsGeocoder";

import { getLocationSlug } from "./getLocationSlug";
import { normalizeGoogleGeocodeResponse } from "./normalizeGeocodeResponse";

/**
 * This function take in a Google placeId, makes call to Google Maps API, and returns a formatted location in a promise.
 *
 * Use this function to get a location from Google Maps.
 */
export const convertGooglePlaceIdToLocation = (
  /**
   *  Place IDs uniquely identify a place in the Google Places database and on Google Maps
   */
  placeId: string,
): Promise<Location> => {
  return new Promise((resolve, reject) => {
    initializeGoogleMapsGeocoder().then((geocoder) => {
      if (!geocoder) {
        return reject(new Error("Google maps geocoder was not initialized"));
      } else {
        geocoder.geocode(
          {
            placeId: placeId,
          },
          (results, status) => {
            if (status === "OK" && results) {
              const location = normalizeGoogleGeocodeResponse({
                response: results,
              });

              location.slug = getLocationSlug(location);

              return resolve(location);
            } else {
              return reject(status);
            }
          },
        );
      }
    });
  });
};

/**
 * This function take in a coordinates of type Coordinates, converts our coordinate to googles LatLngLiteral, makes call to Google Maps API,
 * and returns a formatted location in a promise.
 *
 * Use this function to get a location from Google Maps.
 *
 */
export const convertCoordinatesToLocation = (
  coordinates: Pick<GeolocationCoordinates, "latitude" | "longitude">,
): Promise<Location> => {
  return new Promise((resolve, reject) => {
    initializeGoogleMapsGeocoder().then((geocoder) => {
      if (!geocoder) {
        return reject(new Error("Google maps geocoder was not initialized"));
      } else {
        geocoder.geocode(
          {
            location: {
              lat: coordinates.latitude,
              lng: coordinates.longitude,
            },
          },
          (results, status) => {
            if (status === "OK" && results) {
              const location = normalizeGoogleGeocodeResponse({
                response: results,
              });

              location.slug = getLocationSlug(location);

              return resolve(location);
            } else {
              return reject(status);
            }
          },
        );
      }
    });
  });
};
