import React, { useState } from "react";
import classnames from "classnames";

import AutocompleteInput from "./AutocompleteInput";

type AddressType = {
  addressLine2?: string;
  formattedAddress?: string;
};

type StatusType = { error: boolean; msg: string };

export type DeliveryValidationAutoCompleteProps = {
  handleStatusChange: React.Dispatch<
    React.SetStateAction<{
      error: boolean;
      msg: string;
    }>
  >;
  setUserAddress: (address: AddressType) => void;
  status: StatusType;
  userAddress: AddressType;
};

const DeliveryValidationAutoComplete: React.FC<
  DeliveryValidationAutoCompleteProps
> = ({
  handleStatusChange = () => {},
  setUserAddress,
  status,
  userAddress = { addressLine2: "", formattedAddress: "" },
}) => {
  const [formattedAddress, setFormattedAddress] = useState<string>(
    userAddress?.formattedAddress as string,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "location-line-1") {
      setFormattedAddress(e.target.value);
    }

    if (e.target.name === "address-line-2") {
      setUserAddress({ ...userAddress, addressLine2: e.target.value });
    }
  };

  // @ts-ignore (fix me please, do not replicate)
  const handleSelect = (selection) => {
    setUserAddress({ addressLine2: userAddress.addressLine2, ...selection });
    setFormattedAddress(selection.formattedAddress);
  };

  const onClear = () => setFormattedAddress("");

  return (
    <>
      <div id="address1">
        <span
          className={classnames("font-bold text-xs", {
            "text-error": status.error,
          })}
        >
          Street address
        </span>
        <AutocompleteInput
          address={userAddress}
          onChange={handleChange}
          onSelect={handleSelect}
          onStatusChange={handleStatusChange}
          onClear={onClear}
          // * Avoiding usage of the word "address" to avoid
          // * Chrome's address autocomplete feature
          placeholder="Enter location"
          name="location-line-1"
          status={status}
          formattedAddress={formattedAddress}
        />
      </div>
      <span className="font-bold text-xs">Apartment number</span>
      <input
        name="address-line-2"
        aria-label="address-line-2"
        className="bg-leafly-white border border-light-grey font-bold mb-lg pl-lg rounded text-sm placeholder:text-grey placeholder:text-sm"
        onChange={handleChange}
        value={userAddress?.addressLine2 || ""}
        id="address-line-2"
        data-testid="address-text-input-line-2"
        placeholder="Apartment, suite, etc"
      />
    </>
  );
};

export default DeliveryValidationAutoComplete;
