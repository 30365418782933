import { UserServiceArea } from "./UserServiceArea";

export const deliveryFulfillmentType = "delivery" as const;
export const pickupFulfillmentType = "pickup" as const;
export const shippingFulfillmentType = "shipping" as const;

type DeliveryFulfillmentType = typeof deliveryFulfillmentType;
type PickupFulfillmentType = typeof pickupFulfillmentType;
type ShippingFulfillmentType = typeof shippingFulfillmentType;

export type FulfillmentType =
  | DeliveryFulfillmentType
  | PickupFulfillmentType
  | ShippingFulfillmentType;

export type Brand = {
  id: number;
  featureTier: string;
};

export type Dispensary = {
  id: number;
  distanceMi: number | null;
  featureTier: string;
  inStoreCartEnabled: boolean;
  maxDeliveryDurationMaximum: number | null;
  maxFee: number | null;
  maxOrderMinimum: number | null;
  name: string;
  path: string;
  slug: string;
  userServiceArea?: UserServiceArea;
};

export type MenuDeal = {
  finePrint: string;
  label: string;
  title: string;
};

export type Variant = {
  id: number;
  isInitialSelection: boolean;
  displayPrice: number;
  medical: boolean;
  menuDeal: MenuDeal | null;
  quantity: number;
  regularPrice: number;
  stockQuantity: number; // can this be null?
  unit: string;
};

export type LeaflyFulfillmentDetails = {
  brand: Brand | null;
  dispensary: Dispensary;
  menuItemId: number;
  name: string;
  onlineFulfillmentEnabled: boolean;
  productCategory: string;
  productId: number;
  strainId: number | null;
  variants: Variant[];
};

export type ShippingFulfillmentDetails = {
  affiliateUrl: string;
};

type BaseFulfillment = {
  type: FulfillmentType;
  isInitialSelection: boolean;
};
export type ShippingFulfillment = BaseFulfillment & {
  details: ShippingFulfillmentDetails | null;
};
export type LeaflyFulfillment = BaseFulfillment & {
  details: LeaflyFulfillmentDetails | null;
};

export type FulfillmentOption = LeaflyFulfillment | ShippingFulfillment;
