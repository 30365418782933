import React from "react";
import classnames from "classnames";

import CircleCheckMarkIcon from "components/Icons/circle_check_mark.svg";
import InfoIcon from "components/Icons/info.svg";
import LocationMarkerIcon from "components/Icons/location_marker.svg";

type DeliveryValidationButtonProps = {
  handleClick: () => void;
  address?: string;
  inZone?: boolean;
  className?: string;
  placeholder?: string;
};

const DeliveryValidationButton = React.forwardRef<
  HTMLButtonElement,
  DeliveryValidationButtonProps
>(
  (
    {
      address,
      className,
      handleClick,
      inZone,
      placeholder = "Confirm your delivery address",
    },
    ref,
  ) => {
    return (
      <button
        className={classnames(
          "flex items-center justify-between bg-leafly-white border border-light-grey rounded text-grey w-full py-xs",
          className,
        )}
        onClick={handleClick}
        ref={ref}
        data-testid="delivery-validation-button"
      >
        <div className="flex flex-grow items-center truncate">
          <LocationMarkerIcon
            height="24"
            width="24"
            className="text-default ml-sm"
          />

          <span
            className={classnames("truncate p-2", {
              "text-default": address,
            })}
          >
            {address || placeholder}
          </span>
        </div>

        {typeof inZone === "boolean" && (
          <div className="flex-shrink-0 px-sm">
            {inZone ? (
              <CircleCheckMarkIcon
                height="24"
                width="24"
                className="text-green"
              />
            ) : (
              <InfoIcon height="24" width="24" className="text-error" />
            )}
          </div>
        )}
      </button>
    );
  },
);

export default DeliveryValidationButton;
