import consumerApi from "api/services/consumer-api";
import { DeliveryZoneDecisionResponse } from "custom-types/Compliance";
import { Location } from "custom-types/Location";
import logError from "utils/logError";

const fallbackResponse = {
  result: false,
  violations: [
    {
      message: "Unable to validate user's address for delivery.",
      type: "string",
    },
  ],
};

const getDeliveryZoneFromAddress = async (
  dispensaryId: number,
  customerAddress: Location,
): Promise<DeliveryZoneDecisionResponse> => {
  try {
    const { data } = await consumerApi.post(
      `/api/dispensaries/v1/${dispensaryId}/delivery_eligibility`,
      {
        customerAddress: {
          ...customerAddress,
          lat: customerAddress.latitude,
          lon: customerAddress.longitude,
        },
      },
    );

    if (!data) {
      return fallbackResponse;
    }

    return data;
  } catch (e) {
    logError(e.message, {
      functionName: "getDeliveryZoneFromAddress",
      service: "consumer",
      statusCode: e.statusCode,
    });
    return fallbackResponse;
  }
};

export default getDeliveryZoneFromAddress;
